<template>
  <div class="box">
    <van-search
      v-model="value"
      shape="round"
      placeholder="输入您要搜索的产品名，如：微业贷"
      @search="search"
    >
      <template #left>
        <van-icon name="arrow-left" class="back" @click="back" />
      </template>
    </van-search>
    <div class="history">
      <div class="history-top">
        <div class="title">历史记录</div>
        <van-icon name="delete-o" class="del" @click="del" />
      </div>
      <div class="tag-box">
        <div class="tag" v-for="item in tagList" :key="item" @click="to(item)">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      tagList: [],
    };
  },
  created() {
    this.tagList = localStorage.getItem("searchHistory")
      ? JSON.parse(localStorage.getItem("searchHistory"))
      : [];
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    search() {
      if (this.tagList.indexOf(this.value) == -1 && this.value != "") {
        this.tagList.push(this.value);
        localStorage.setItem("searchHistory", JSON.stringify(this.tagList));
      }
      this.to(this.value);
    },
    del() {
      this.tagList = [];
      localStorage.removeItem("searchHistory");
    },
    to(val) {
      this.$router.push({
        path: "productList",
        query: { search: val },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .back {
    position: relative;
    font-size: 20px;
    right: 10px;
  }
  .history {
    width: 345px;
    margin: 0 auto;
    margin-top: 10px;
    .history-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
      }
      .del {
        font-size: 15px;
        color: #bbbbbb;
      }
    }
    .tag-box {
      width: 345px;
      margin: 0 auto;
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      .tag {
        background: #f8f8f8;
        padding: 4px 16px;
        border-radius: 2px;
        color: #555555;
        font-size: 12px;
        margin-left: 15px;
        margin-bottom: 12px;
      }
    }
  }
}
</style>
